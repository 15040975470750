var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"church-ministry template-1"},[_c('header',{staticClass:"page-header container"},[_c('h1',[_vm._v(_vm._s(_vm.translations.tcPrayerAndPersonalWitnessing))])]),(!_vm.allowPage)?_c('security-banner',{attrs:{"i18n":_vm.translations.components}}):_vm._e(),(_vm.allowPage)?_c('div',[_c('camp-selector',{attrs:{"tooltip":_vm.translations.tcTooltip,"i18n":_vm.translations.components},on:{"camp_changed":function($event){return _vm.page_load()}}}),_c('div',{staticClass:"page-body container mx-med"},[_c('section',{staticClass:"section-0",attrs:{"id":"memoryverse"}},[_c('h4',[_vm._v(_vm._s(_vm.translations.tcScriptureMemoryVerseOfTheWeek))]),_c('div',{domProps:{"innerHTML":_vm._s(this.scriptureVerse)}})]),_c('section',{staticClass:"section-1"},[_c('h4',[_vm._v(_vm._s(_vm.translations.tcPersonalWitnessingEncounters))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6"},_vm._l((_vm.opportunities),function(opportunity,index){return _c('DateCard',{key:index,attrs:{"obj":opportunity.date,"i18n":_vm.translations.common}},[(_vm.translations.common)?_c('BasicDateCard',{attrs:{"i18n":_vm.translations.components,"obj":{
                  title: opportunity.wit_name,
                  description: opportunity.wit_content,
                  schedule: opportunity.wit_name,
                  wit_key: opportunity.wit_key,
                  destination: opportunity.destination,
                  wit_name: opportunity.wit_name,
                  wit_content: opportunity.wit_content,
                  wit_add_date: opportunity.wit_add_date,
                  date: opportunity.date,
                }},on:{"Witnessing_clicked":function($event){return _vm.handleWitnessingEncounterClick($event)}}}):_vm._e()],1)}),1),_c('div',{staticClass:"col col-6"},_vm._l((_vm.churchVisitsData),function(stat,index){return _c('NumberCard',{key:index,attrs:{"obj":stat,"i18n":_vm.translations.components}})}),1)]),_c('div',{staticClass:"d-flex button-bar"},[_c('a',{staticClass:"btn btn-primary btn-w-med mr-4 mt-3 mb-3",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleViewMoreClick($event)}}},[_vm._v(_vm._s(_vm.translations.tcViewMore))]),_c('button',{staticClass:"btn btn-primary btn-w-med mt-3 mb-3",on:{"click":_vm.handleAddWitnessingEncounterClick}},[_vm._v(" "+_vm._s(_vm.translations.tcAddWitnessingEncounter)+" ")]),_c('span',{staticClass:"sub-text ml-4"},[_vm._v(_vm._s(_vm.translations.tcRecordOpportunityToShareChrist))])])]),_c('section',{staticClass:"section-3"},[_c('div',{staticClass:"row"},_vm._l((_vm.churchMinistryInfo),function(info,index){return _c('InfoCard',{key:index,attrs:{"obj":info}})}),1)])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }